const uty = {
    extend: function () {
        /* https://gomakethings.com/vanilla-javascript-version-of-jquery-extend/ */
        // Variables
        let _t = this,
            extended = {},
            deep = false,
            i = 0,
            length = arguments.length;

        // Check if a deep merge
        if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]') {
            deep = arguments[0];
            i++;
        }

        // Merge the object into the extended object
        let merge = function (obj) {
            for (prop in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                    // If deep merge and property is an object, merge properties
                    if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
                        extended[prop] = _t.extend(true, extended[prop], obj[prop]);
                    } else {
                        extended[prop] = obj[prop];
                    }
                }
            }
        };
        
        // Loop through each object and conduct a merge
        for (; i < length; i++) {
            let obj = arguments[i];
            merge(obj);
        }
        
        return extended;

    },
    hasClass: (elm, cls) => {
        let hasCls = false;

        if ( (' ' + elm.className + ' ').replace(/[\n\t]/g, ' ').indexOf(' ' + cls + ' ') > -1)
            hasCls = true;

        return hasCls;
    },
    loadJS: (path, location, callback) => {
        let file = document.createElement('script');
        file.src = path;
        file.onload = callback;
        location.appendChild(file);
    },
    detectEl: function (k) {
        return k == null ? false : true;
    },
    forEach: function (array, callback, scope) {
        //https://css-tricks.com/snippets/javascript/loop-queryselectorall-matches/
        for (var i = 0; i < array.length; i++) {
            callback.call(scope, i, array[i]); // passes back stuff we need
        }
    },
    responsiveControl: function () {
        var _t = this,
            responsive = '(max-width: 1024px)',
            b = false;
        if (window.matchMedia(responsive).matches)
            b = true;

        return b;
    },
    setClass: function (o) {
        /* 
            addClass, removeClass için fonk.
            utils.setClass({ target: btn, cls: _t.opt.selected, type: 'remove' });
        */
        o = o || {};
        var _t = this,
            target = o['target'],
            cls = (o['cls'] || '').split(' '),
            type = o['type'] || 'add'; // add, remove degerlerini alir 

        _t.forEach(target, function (i, elm) {
            _t.forEach(cls, function (j, k) {
                if (type == 'add')
                    elm.classList.add(k);
                else
                    elm.classList.remove(k);
            });

        });
    },
    pageScroll: function(o){
        o = o || {};
        
        window.scrollTo({
            'behavior': 'smooth',
            'left': o['left'] || 0,
            'top': o['top'] || 0
        });
        
    },
    ajx: function (o, callback) {
        $.ajax({
            type: o['type'] || 'GET',
            dataType: o['dataType'] || 'html',
            url: o['uri'] || '',
            data: o['param'] || {},
            contentType: o['contentType'] || '',
            error: function (e) {
                if (typeof callback !== 'undefined')
                    callback({ type: 'error' });
            },
            timeout: 30000,
            success: function (d) {
                if (typeof callback !== 'undefined')
                    callback({ type: 'success', val: d });
            }
        });
    }
};

jQuery.cachedScript = function( url, options ) {
    // Allow user to set any option except for dataType, cache, and url
    options = $.extend( options || {}, {
      dataType: "script",
      cache: true,
      url: url
    });
   
    // Use $.ajax() since it is more flexible than $.getScript
    // Return the jqXHR object so we can chain callbacks
    return jQuery.ajax( options );
};