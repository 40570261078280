// Config setter functions
const SET_CONFIG = {
    getTransitionEndEventName: () => {
        let pool = {
            "transition"      : "transitionend",
            "OTransition"     : "oTransitionEnd",
            "MozTransition"   : "transitionend",
            "WebkitTransition": "webkitTransitionEnd"
        }
        let bodyStyle = document.body.style;
        for(i in pool)
            if(bodyStyle[i] != undefined)
                return pool[i];
    },
    getAnimationEndEventName: () => {
        let pool = {
            "animation"      : "animationend",
            "OAnimation"     : "oAnimationEnd",
            "MozAnimation"   : "animationend",
            "WebkitAnimation": "webkitAnimationEnd"
        }
        let bodyStyle = document.body.style;
        for(i in pool)
            if(bodyStyle[i] != undefined)
                return pool[i];
    }
};

// Config
const CONFIG = {
    general: {
        eventNames:{
            transitionEnd: SET_CONFIG.getTransitionEndEventName(),
            animationEnd: SET_CONFIG.getAnimationEndEventName()
        }
    },
    plugins: {
        tns: {
            main: {
                container: '#main-slider',
                items: 1,
                mouseDrag: true
            },
            dual: {
                container: '#dual-slider',
                items: 2,
                gutter: 35,
                slideBy: 2,
                nav: false
            },
            plpCat: {
                container: '#plp-mbcat-slider',
                autoWidth: true,
                rewind: true,
                nav: false,
                controls: false,
                gutter: 15,
                mouseDrag: true,
                swipeAngle: false
            },
            dualCat: {
                container: '#dual-slider-categroy',
                gutter: 20,
                slideBy: 2,
                responsive: {
                    320:{
                        items: 1
                    },
                    1025:{
                        items: 2
                    }
                }
            },
            brand: {
                container: '#brand-slider',
                autoplay: true,
                rewind: true,
                nav: false,
                controls: false,
                gutter: 30,
                swipeAngle: false,
                loop: true,
                responsive: {
                    320:{
                        items: 4.5
                    },
                    1025:{
                        items: 8,
                        loop: false
                    }
                }
            },
            widgetWeek: {
                container: '#product-tns',
                autoplay: true,
                rewind: true,
                nav: false,
                gutter: 10, 
                swipeAngle: false,
                loop: true,
                responsive: {
                    320:{
                        items: 1.9,
                        controls: false
                    },
                    1025:{
                        items: 6,
                        controls: true
                    }
                }
            },
            widgetYou: {
                container: '#product-tns-you',
                autoplay: true,
                rewind: true,
                nav: false,
                gutter: 10, 
                swipeAngle: false,
                loop: true,
                responsive: {
                    320:{
                        items: 1.9,
                        controls: false
                    },
                    1025:{
                        items: 6,
                        controls: true
                    }
                }
            },
            widgetDetailCat: {
                container: '#product-detail-cat',
                autoplay: true,
                rewind: true,
                nav: false,
                gutter: 10, 
                swipeAngle: false,
                loop: true,
                responsive: {
                    320:{
                        items: 1.9,
                        controls: false
                    },
                    1025:{
                        items: 6,
                        controls: true
                    }
                }
            },
            widgetDetailBrand: {
                container: '#product-detail-brand',
                autoplay: true,
                rewind: true,
                nav: false,
                gutter: 10, 
                swipeAngle: false,
                loop: true,
                responsive: {
                    320:{
                        items: 1.9,
                        controls: false
                    },
                    1025:{
                        items: 6,
                        controls: true
                    }
                }
            },
            specialForYou: {
                container: '#special-for-you',
                rewind: true,
                nav: true,
                gutter: 10, 
                controls: false,
                swipeAngle: false,
                responsive: {
                    320:{
                        items: 2,
                        autoplay: true
                    },
                    1025:{
                        autoplay: false,
                        items: 4,
                        slideBy: 4
                    }
                }
            },
            pdpCamp: {
                container: '#pdp-camp-slider',
                nav: false,
                controls: false,
                mouseDrag: true,
                responsive: {
                    320:{
                        items: 2.7,
                        gutter: 20,
                        autoWidth: false
                    },
                    1025:{
                        items: false,
                        autoWidth: true
                    }
                }
            },
            pdpGallery: {
                container: '#pdp-gallery',
                items: 1,
                navContainer: "#pdp-gallery-thumbnails",
                navAsThumbnails: true,
                controls: false,
                mouseDrag: true
            },
            looped: {
                loop: true,
                slideBy: 2
            }
        },
        gsap: {
            header: {
                scrollTrigger: {
                    trigger: '#trigger',
                    onEnter: function(e){
                        console.log('HEADER STARTED');
                    }
                }
            },
            rotator: {
                ease: 'Strong.easeInOut', 
                rotation: -10, 
                startAt: {
                    rotation: 10
                }, 
                repeat: 1, 
                yoyo: true, 
                yoyoEase: 'power2.in', 
                repeatDelay: 0.5,
                scrollTrigger: {
                    onEnter: function(e){
                        console.log('STARTED');
                        console.log(e);
                    },
                    onLeaveBack: function(e){
                        console.log('ENDED');
                        console.log(e);
                    }
                }
            },
            slidingDoorsLeft: {
                ease: 'Strong.easeInOut', 
                x: 200
            },
            slidingDoorsRight: {
                ease: 'Strong.easeInOut', 
                x: -200
            }
        }
    }
};